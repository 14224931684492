<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Member Profile" icon="user" />

    <div class="generalBox border shadow-sm">
        <div v-if="user" class="row">
            <div class="col-12 pb-2">
                <table width="100%">
                    <tr>
                        <td class="fw-bold">
                            <Popper class="popperDark" arrow hover content="Back">
                                <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/team/member' })" />
                            </Popper>
                            Personal Information
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12 pb-3 text-center">
                <table border="0" width="100%">
                    <tr>
                        <td width="140">
                            <div v-if="user.jAvatar && user.jAvatar" class="d-flex justify-content-center text-center" style="width: 120px; height: 120px">
                                <ImgAvatar :isUser="user.isUser" :id="user.jAvatar.userId" displayName="" :isVerified="user.isVerified" :isActive="user.userStatus" privilege="" width="120" height="120" />
                                <!-- <img v-if="user.jAvatar && user.jAvatar.imgBase64" :src="'data:'+ user.jAvatar.mimeType +';base64, ' + user.jAvatar.imgBase64" class="rounded-circle" align="center" style="position:absolute; width: 120px; height: 120px; object-fit: cover" />
                                <fa v-else icon="user" style="position:absolute; width: 116px; height: 116px;" />

                                <span v-if="user.memberStatus === 'PENDING'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                    <fa icon="hourglass-half" class="text-danger" />
                                </span>
                                <span v-else-if="user.memberStatus === 'DISABLED' || user.memberStatus === 'EXPIRED' || user.memberStatus === 'DELETING' || user.memberStatus === 'REACTIVE'" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                    <fa icon="circle-minus" class="text-danger" />
                                </span>
                                <span v-else-if="user.isVerified === true" id="certificate" style="z-index:1000; font-size: 28px; margin-top: 82px; margin-left: 95px; position:absolute;">
                                    <fa icon="circle-check" class="text-success" />
                                </span> -->   
                            </div>
                        </td>
                        
                        <td class="text-start">
                            <div>
                                <span class="fw-bold">{{ user.fullName }}</span>
                                <span v-if="user.displayName" class="text-grey">
                                    <fa icon="at" class="mx-1"/>{{user.displayName}}
                                </span>
                                
                            </div>
                            <div>{{user.userId}}</div>
                            <div>{{user.memberStatus}}</div>

                            <!-- tmp hide addTag: 
                            <div>
                                <span>
                                    <div v-if="showDropdown === false" class="badge tag" v-for="(t, tIdx) in user.tags" :key="t">
                                        <fa icon="hashtag" class="pe-1"/>{{ t }}
                                        <span class="d-lg-none">
                                            <a href="#" class="ms-1" @click="delTag(t, tIdx)"><fa icon="xmark" /></a>
                                        </span>
                                        <span class="tagClose" @click="delTag(t, tIdx)"><fa icon="xmark" /></span>
                                    </div>
                                    
                                    <div class="badge btnTag isLink text-white" @click="showDropdown = !showDropdown">
                                        <span v-if="showDropdown">
                                            <Popper class="popperDark" arrow hover content="Close add tag">
                                            <fa icon="minus" />
                                            </Popper>
                                        </span>
                                        <span v-else>
                                            <Popper class="popperDark" arrow hover content="Add tag">
                                            <fa icon="plus" />
                                            </Popper>
                                        </span>
                                    </div>

                                    <div v-if="showDropdown" class="custom-dropdown">
                                        <Tag :tags="user.tags" @delTag="delTag" />
                                        <input type="text" v-model="userTag" ref="userTagRef" class="inputTag my-2" autocomplete="off" 
                                        @keyup.enter="enterUserTag()"  autofocus />
                                    </div>
                                </span>
                            </div> -->

                            <div><br /></div>
                            <div v-if="user.dateJoin && user.dateJoin !== 0" class="fst-italic">joined {{func.convDateTimeFormat(user.dateJoin, 'date')}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>    

        <div class="row">
            <div class="col-12 col-lg-6 pb-3">
                <div class="form-floating">
                    <input v-model="fullLegalName" type="text" class="form-control" placeholder="Full legal name" id="fullLegalName">
                    <label for="fullLegalName">Full legal name</label>
                </div>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <div class="form-floating">
                    <input v-model="displayName" type="text" class="form-control" placeholder="Display name">
                    <label for="displayName">Display name</label>
                </div>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <div class="form-floating">
                    <select v-model="title" class="form-select" id="title">
                        <option value="null">Select title</option>
                        <option :value="t.value" v-for="t in titleOpt" :key="t.value">{{ t.label }}</option>
                    </select>
                    <label for="title">Title</label>
                </div>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <div class="form-floating">
                    <select v-model="gender" class="form-select" id="gender">
                        <option value="null">Select gender</option>
                        <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                    </select>
                    <label for="gender">Gender</label>
                </div>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <div class="form-floating">
                    <select v-model="gmt" class="form-select">
                        <option value="null">Select GMT</option>
                        <option :value="g.value" v-for="g in gmtOpt" :key="g.value">{{g.label}}</option>
                    </select>
                    <label for="gmt">GMT</label>
                </div>
            </div>

            <div class="col-12 text-center">
                <button class="btn btn-secondary me-2">
                    <fa icon="user-slash" size="lg" class="me-2" />Remove from entity
                </button>
            </div>
        </div>
    </div>

    <div v-if="isSignOn === true" class="generalBorderlessBox mt-0">
        <div class="row p-0">
            <div class="col-lg-6">
                <div class="generalContentBox border shadow-sm">
                    <div class="fw-bold mb-2">Recent activities</div>
                    <div>
                        <table class="table table-hover">
                            <tbody>
                                <tr>
                                    <td width="40px">
                                        <ImgAvatar isUser="true" id="" displayName="" isVerified="" isActive="" privilege="" width="30" height="30" />
                                        <!-- <Avatar isUser="false" avatar="" displayName="" isVerified="true" width="30" height="30" /> -->
                                    </td>
                                    <td>
                                        <div class="fw-bold text-grey">Agreement.pdf</div>
                                        <div class="text-grey">Signed the document</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-25T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40px">
                                        <ImgAvatar isUser="true" id="" displayName="" isVerified="" isActive="" privilege="" width="30" height="30" />
                                        <!-- <Avatar isUser="false" avatar="" displayName="" isVerified="true" width="30" height="30" /> -->
                                    </td>
                                    <td>
                                        <div class="fw-bold text-grey">Sales and Purchase Agreement.pdf</div>
                                        <div class="text-grey">Sent the document</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-23T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="generalContentBox border shadow-sm">
                    <div class="fw-bold mb-2">Recent SSO</div>
                    <div>
                        <table class="table table-hover">
                            <tbody>
                                <tr>
                                    <td><fa :icon="['fab', 'firefox']" class="text-grey" style="font-size: 25px" /></td>
                                    <td>
                                        <div class="fw-bold text-grey">Firefox (Windows)</div>
                                        <div class="text-grey"><fa icon="location-dot" /> Kuala Lumpur, Malaysia</div>
                                        <div class="lblTiny text-grey fst-italic text-end">Last active {{ func.getDateTimeDiff(func.convDateTimeFormat('2022-08-01T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><fa :icon="['fab', 'chrome']" class="text-grey" style="font-size: 25px" /></td>
                                    <td>
                                        <div class="fw-bold text-grey">Chrome (MacOS)</div>
                                        <div class="text-grey"><fa icon="location-dot"/> New York, United States</div>
                                        <div class="lblTiny text-grey fst-italic text-end">Last active {{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-29T10:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="generalContentBox border shadow-sm">
                    <div class="fw-bold mb-2">Recent folder / doc created</div>
                    <div>
                        <table class="table table-hover">
                            <tbody>

                                <tr>
                                    <td>
                                        <div class="fw-bold text-grey">Sample Agreement.pdf</div>
                                        <div class="text-grey"><fa icon="folder" class="me-2" />Project III</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-08-01T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="fw-bold text-grey">Sales and Purchase Agreement.pdf</div>
                                        <div class="text-grey"><fa icon="folder" class="me-2" />SP Setia Phase II</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-29T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="fw-bold text-grey">Sample Agreement.pdf</div>
                                        <div class="text-grey"><fa icon="folder" class="me-2" />Project III</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-28T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="fw-bold text-grey">Testing.pdf</div>
                                        <div class="text-grey"><fa icon="folder" class="me-2" />Test Folder</div>
                                        <div class="lblTiny text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat('2022-07-25T12:00:25.398Z'), new Date()) }}</div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import Tag from '@/components/ProjTag.vue'

export default {
    name: 'MemberProfile',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar, Tag },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const user = ref({})
        const userTag = ref(null)
        const userTagRef = ref(null)
        const showDropdown = ref(false)
        const newPassword = ref(null)

        const fullLegalName = ref(null)
        const displayName = ref(null)
        const title = ref(null)
        const gender = ref(null)
        const gmt = ref(null)
        const plan = ref(null)
        const gmtOpt = ref([])
        const titleOpt = ref([])

        const isSignOn = ref(false) // detect if from signon Entity
        
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getUser = () => {
            axios.get('/entity/member/' + route.params.userId)
            .then((res) => {

                if (res.data.status === 1) {
                    user.value = res.data.data
                    if (res.data.data.entityId === '1') {
                        isSignOn.value = true
                    }
                    
                    fullLegalName.value = user.value.fullName
                    displayName.value = user.value.displayName
                    title.value = user.value.title
                    gender.value = user.value.gender
                    gmt.value = user.value.gmt

                } else {
                    func.addLog('teamMemberProfile', 'getUser', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                }
            })
            .catch((error) => {
                func.addLog('teamMemberProfile', 'getUser - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const updUser = () => { 
            const p = {
                fullName: fullLegalName.value,
                displayName: displayName.value,
                gender: gender.value,
                title: title.value,
                gmtl: gmt.value
            }

            console.info('updUser ' + route.params.userId, JSON.stringify(p))

        }

        const getGmtOpt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data

                    } else {
                        func.addLog('teamMemberProfile', 'getGmtOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMemberProfile', 'getGmtOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getTitleOpt = () => {
            axios.get('/reference/title')
                .then((res) => {
                    if (res.data.status === 1) {
                        titleOpt.value = res.data.data

                    } else {
                        func.addLog('teamMemberProfile', 'getTitleOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMemberProfile', 'getTitleOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const delTag = (val, idx) => {
            updTag('del', val, idx)
        }

        const updTag = async (opt, val, idx=0) => {
            const tmpTag = user.value.tags

            if (opt === 'add') {
                tmpTag.push(val)

                // mp -  assume added successfully
                if (!user.value.tags.includes(val))
                {
                    user.value.tags.push(val)
                }
                    userTag.value = null 

            } else {
                const tmpTag = tags.value
                tmpTag.splice(idx, 1)
            }

            /* const  p = {
                tags: tmpTag
            }

            axios.put( '/signon/' + route.params.projectId, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        userTag.value = null

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: opt === 'add' ? "Tag is added." : "Tag is removed."
                        })

                    } else {
                        func.addLog('teamMemberProfile', 'updTag', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('teamMemberProfile', 'updTag - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                }) */
        }

        const enterUserTag = () => {
            if (userTag.value.length > 0) {
                if (user.value.tags.includes(userTag.value)) {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Tag already exists.'
                    })

                } else {
                    updTag('add', userTag.value)

                }
            }

        }

        const updPassword = () => {
            // console.info('updPassword', newPassword.value)
        }
        
        onMounted(() => {
            getUser()
            getGmtOpt()
            getTitleOpt()
           
        })

        return {
            alert, closeAlert, route, router, func, store, 
            fullLegalName, displayName, title, gender, gmt, plan, gmtOpt, titleOpt, genderOpt, 
            user, delTag, userTag, userTagRef, enterUserTag, showDropdown,
            updPassword, newPassword, updUser, isSignOn
        }
    }
}
</script>

<style>
.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
    visibility: visible;
    background-color: white;
    width: 122px;
}

.editLink {
    font-size: 18px;
    padding: 8px; 
}

.editLink:hover {
    background-color: #DADADB;
    padding: 8px 10px;
    border-radius: 20px;
}
</style>